import React from "react";

import { Icon } from '@types';

const NotFound: Icon = ({ fill }) => (
  <svg 
    viewBox="0 0 408 192"
    xmlns="http://www.w3.org/2000/svg"
  >
  <path
    d="M200 0v192c-35.5231 0-56-15.9855-56-47.5181V47.518C144 15.9855 164.4769 0 200 0zm-80 0v192c-35.5231 0-56-15.9855-56-47.5181V47.518C64 15.9855 84.4769 0 120 0zm288 0v192c-35.5231 0-56-15.9855-56-47.5181V47.518C352 15.9855 372.4769 0 408 0zM208 0c35.5231 0 56 15.9855 56 47.5181v96.9638C264 176.0145 243.5231 192 208 192zm-16 8.302l-.7963.0665C165.059 10.675 152 23.801 152 47.5181v96.9638c0 23.9568 13.324 37.1072 40 39.2162V8.3019zm-80 0l-.7963.0665C85.059 10.675 72 23.801 72 47.5181v96.9638c0 23.9568 13.324 37.1072 40 39.2162V8.3019zm288 0l-.7963.0665C373.0588 10.675 360 23.801 360 47.5181v96.9638c0 23.9568 13.324 37.1072 40 39.2162V8.3019zm-184 0v175.396c26.676-2.109 40-15.2593 40-39.2161V47.518c0-23.7172-13.0589-36.843-39.2037-39.1496L216 8.302zM56 16v104c-35.5231 0-56-15.9673-56-47.464v-9.072C0 31.9672 20.4769 16 56 16zm288 0v104c-35.5231 0-56-15.9673-56-47.464v-9.072C288 31.9672 308.4769 16 344 16zM48 24.3015l-.7963.0665C21.0577 26.6716 8 39.7807 8 63.464v9.072c0 23.9225 13.3228 37.0562 40 39.1625v-87.397zm288 0l-.7963.0665C309.0577 26.6716 296 39.7807 296 63.464v9.072c0 23.9225 13.3228 37.0562 40 39.1625v-87.397z"
    fill={fill}
    fillRule="nonzero"
    />
  </svg>
);

export default NotFound;
